<script>
import { BATCH_TRANSACTIONS_BY_MONTH, BATCHES } from '@/graphql/queries';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import toInt from 'lodash/toInteger';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    apexcharts: () => import('vue-apexcharts'),
  },

  data() {
    return {
      loading: false,
      maxY: 6,
      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          show: true,
          width: 3,
          colors: ['transparent'],
          curve: 'smooth',
        },

        markers: {
          size: 5,
          colors: '#000000',
          strokeOpacity: 0.9,
          strokeColors: '#000000',
        },

        title: {
          text: 'Perfil Alimentar',
          align: 'center',
        },

        subtitle: {
          text: 'Oferta e demanda diária de pasto nos módulos',
          align: 'center',
        },

        yaxis: [
          {
            labels: {
              formatter: function (val) {
                if (typeof val === 'number')
                  return val.toLocaleString(undefined, { useGrouping: true, maximumFractionDigits: 2 });
                return val;
              },
            },

            title: {
              text: 'Kg de MS/dia',
            },
          },
        ],

        xaxis: {
          categories: [],
        },

        fill: {
          opacity: 1,
        },
      },

      series: [],
    };
  },

  computed: {
    ...mapState('dashboard', ['filter', 'paddocks']),
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  watch: {
    filter() {
      this.fetch();
    },
  },

  methods: {
    async fetch() {
      this.loading = true;
      let form = {};
      form.farm_id = this.filter.farmId;
      form.module_id = this.filter.moduleId;
      form.calc_type = 'NUMBER_OF_HEADS_THAT_FIT_IN_MODULE';
      form.genetic_pattern = 'ZEBUINO';
      form.category_animal = 'MACHO_12';
      form.category_complementary = null;
      form.day_occupation = 1;
      form.number_of_heads = 1;
      form.average_batch_weight = 1;
      form.expected_performance = 1;
      form.supplement_mineral_consumption = null;
      form.supplement_mineral_pb = null;
      form.supplement_mineral_ndt = null;
      form.supplement_feed_consumption = null;
      form.supplement_feed_pb = null;
      form.supplement_feed_ndt = null;
      form.supplement_feed_fdn = null;
      form.supplement_vol_consumption = null;
      form.supplement_vol_ms = null;
      form.supplement_vol_pb = null;
      form.supplement_vol_em = null;
      form.supplement_vol_fdn = null;
      form.calculator = true;
      const filter = this.filter;
      const arrayStart = this.filter.startAt.split('-');
      const arrayEnd = this.filter.endAt.split('-');
      const start = toInt(arrayStart[1]);
      const end = toInt(arrayEnd[1]);
      const yearStart = toInt(arrayStart[0]);
      const yearEnd = toInt(arrayEnd[0]);
      let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      let years = yearEnd - yearStart;
      if (years === 0) {
        this.chartOptions.xaxis.categories = months.slice(start - 1, 12 - start);
      } else {
        let arrayMonths = months.slice(start - 1, 12);
        for (let i = 1; i < years - 1; i++) {
          arrayMonths = [...arrayMonths, ...months.slice(0, 12)];
        }
        arrayMonths = [...arrayMonths, ...months.slice(0, end - 1)];
        this.chartOptions.xaxis.categories = arrayMonths;
      }
      const series = [];
      series[0] = [];
      series[1] = [];
      years = years + 1;
      let startFor = start;
      let endFor = 13;
      let where = {};
      where.module_id = { _eq: filter.moduleId };
      where.deleted_at = { _is_null: true };
      const { data } = await this.$apollo.query({ query: BATCHES, variables: { where } });
      const batches = data.batches;
      const or = [];
      for (let index = 0; index < batches.length; index++) {
        or.push({ batch_id: { _eq: batches[index].id } });
      }
      for (let index = 0; index < years; index++) {
        if (index + 1 === years) {
          endFor = end;
        }
        for (let i = startFor; i < endFor; i++) {
          let occupation = 0
          where = { _and: [], _or: [] };
          let dateStart = arrayStart[0] + '-' + i.toString() + '-01T00:00:00';
          let dateEnd;
          if (i < 12) {
            dateEnd = arrayStart[0] + '-' + (i + 1).toString() + '-01T00:00:00';
          } else {
            let year = toInt(arrayStart[0]) + 1;
            year = year.toString();
            dateEnd = year + '-01' + '-01T00:00:00';
          }
          where._or = or;
          where._and.push({ event_date: { _gte: dateStart } });
          where._and.push({ event_date: { _lte: dateEnd } });
          const { data } = await this.$apollo.query({ query: BATCH_TRANSACTIONS_BY_MONTH, variables: { where } });
          const transactions = data.batch_transactions;
          let comsumption = 0;
          let interval = 0;
          for (let i = 0; i < transactions.length; i++) {
            if (!(i === 0 && transactions[i].type === 'FECHAMENTO')) {
              comsumption =
                comsumption +
                transactions[i].expected_consumption * transactions[i].number_of_heads * transactions[i].interval;
              interval = interval + transactions[i].interval;
              occupation = occupation + (transactions[i].day_occupation* transactions[i].interval)
            }
          }
          occupation = occupation / interval;
          comsumption = comsumption / interval;
          comsumption = comsumption ? comsumption : 0;
          series[0].push(comsumption.toFixed(0));
          try {
            form.reference_month = arrayStart[0] + '-' + i.toString();
            const res = await this.$apollo.query({
              query: gql`
                query calc($data: CalcInput!) {
                  calc(data: $data) {
                    result
                  }
                }
              `,
              variables: {
                data: form,
              },
              fetchPolicy: 'no-cache',
            });
            const configModuleDataAndAdjustedOffer = {
              moduleArea: { label: 'Área do módulo (ha)', position: 1 },
              qtdPaddocks: { label: 'Quantidade de piquetes', position: 2 },
              availableDryMatter: { label: 'Matéria seca disponível (kg/piquete)', position: 3 },
              crudeProtein: { label: 'Proteína bruta', position: 4 },
              fiberInNeutralDetergent: { label: 'Fibra em detergente neutro', position: 5 },
              metabolizableEnergy: { label: 'Energia Metabolizável (Mcal/kg)', position: 6 },
            };
            const moduleDataAndAdjustedOffer = Object.entries(res.data.calc.result.moduleDataAndAdjustedOffer)
              .map(([name, value]) => {
                return {
                  name,
                  value,
                  ...configModuleDataAndAdjustedOffer[name],
                };
              })
              .filter((item) => item.label)
              .sort((a, b) => (a.position > b.position ? 1 : -1));
            if (occupation > 0) {
              series[1].push((moduleDataAndAdjustedOffer[2].value / occupation).toFixed(0));
            } else {
              series[1].push(moduleDataAndAdjustedOffer[2].value.toFixed(0));
            }
          } catch (e) {
            series[1].push(0);
          }
        }
        arrayStart[0] = toInt(arrayStart[0]) + 1;
        arrayStart[0] = arrayStart[0].toString();
        startFor = 1;
      }
      this.mountChart(series);
    },

    mountChart(val) {
      const chartOptions = cloneDeep(this.chartOptions);
      const series = [
        {
          name: 'Oferta',
          type: 'column',
          data: val[1],
          color: '#008000',
        },
        {
          name: 'Demanda',
          type: 'line',
          data: val[0],
          color: '#000000',
        },
      ];
      chartOptions.colors = series.map((item) => item.color);
      this.chartOptions = chartOptions;
      this.series = series;
      this.maxY = 100;
      this.loading = false;
    },

    legendClickHandler(chartContext, seriesIndex) {
      let paddockId = this.series[seriesIndex].id;
      if (this.filter.paddockIds && this.filter.paddockIds.length === 1) {
        this.filter.paddockIds = [];
      } else {
        this.filter.paddockIds = [paddockId];
      }
      this.$store.dispatch('dashboard/changeFilter', this.filter);
    },
  },
};
</script>

<template>
  <v-card :loading="loading" class="chatOne">
    <v-card-text>
      <v-row>
        <v-col class="overflow-x-auto">
          <apexcharts v-show="!loading" height="500" class="overflow-chart" :options="chartOptions" :series="series" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.apexchartschartxone {
  .apexcharts-tooltip-text-goals-label {
    .apexcharts-tooltip-marker {
      display: none;
    }
  }
}
.overflow-chart {
  min-width: 1000px;
}

.apexcharts-canvas { 
  background-image: url("../../../../../functions/images/mark-small-opacity20.png") !important;
  background-position: center;
  background-repeat:no-repeat;
}
</style>
